@use '~src/modules/components/scss/vars' as *;
@use '~src/modules/components/scss/mixin' as *;

.cmp-map {
  &__styles-dropdown {
    background-color: $color-white;
    border-radius: 16px;
    padding: 4px 16px;
    box-shadow: 0px 2px 6px rgba(116, 116, 116, 0.25);
    cursor: pointer;
    margin: 4px 0;

    &--label {
      cursor: pointer;
      font-weight: 500;
    }
  }
}
