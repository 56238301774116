@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-breadcrumbs {
  &__item {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
}
