@use '~src/modules/components/scss/vars' as *;
@use '~src/modules/components/scss/mixin' as *;

.cmp-network-status-toast {
  .Toastify__close-button {
    > svg {
      fill: #000 !important;
    }
  }
}
