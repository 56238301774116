@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-text {
  white-space: pre-wrap;

  &__title {


    letter-spacing: 0.05em;
    color: $color-black-500;
  }
}