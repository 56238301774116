@use '../../../scss/vars' as *;
@use '../../../scss/mixin' as *;

.cmp-select-rows-per-page {
  text-align: left;

  .cmp-select-rows-per-page {
    &__single-value {
      font-size: 14px;
    }

    &__input {
      input {
        &:disabled {
          visibility: visible;
        }
      }
    }

    &__control {
      height: $input-small;
      min-height: $input-small;
      border: $default-border;
      border-color: '#787878';
      background-color: $color-white;
      transition: $default-transition;
      border-radius: 4px;
      font-size: $body-size;

      &--is-disabled {
        background-color: $color-input-disabled;
        border: none;
        color: $color-primary-800;

        .cmp-select-rows-per-page__single-value {
          color: $color-primary-800;
        }
      }

      &:hover {
        border-color: $color-primary-300;
        background-color: $color-primary-100;
      }

      &:active &--is-focused {
        background-color: $color-primary-500;
        box-shadow: none;
      }
    }

    &__placeholder {
      color: $color-placeholder;
    }

    &__value-container {
      padding: 0px 0px 0px 8px;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: $default-border;
    }

    &__option {
      font-size: 14px;
      padding-left: 16px;
      padding-right: 16px;

      &--is-selected {
        color: $color-white;
        background-color: $color-primary-600;
        font-weight: 600;
        border: $default-border;

        &::after {
          content: '';
        }
      }
    }
  }
}

.cmp-select-rows-per-page__indicators {
  height: 28px;
}
