@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-grid {
  &__item {
    &--empty.column {
      @include tabletScreen {
        display: none;
      }
    }
  }
}