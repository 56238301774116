@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-view-item {
  &__label {
    font-weight: 700;
    color: $color-grey-800;
    font-size: 16px;
  }

  &__value {
    font-size: $body-size;
  }

  &__empty {
    @include mobileScreen {
      display: none;
    }
  }

  &--row {
    display: flex !important;
    align-items: flex-start;
    & .cmp-view-item {
      &__label {
        min-width: 120px;
      }
    }
  }
}
