@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-loading {
  transform: translateZ(0);
  z-index: 102;

  &--normal {
    transform: scale(1);
  }

  &--small {
    zoom: 0.3;
  }

  &#{&}--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cmp-loading {
  .cmp-loading__style-1 {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: auto;

    animation: rotate 7s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }

      0% {
        transform: rotate(360deg);
      }
    }

    &--child {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-primary;
      border-radius: 50%;
      animation: zoomAnim 2s linear infinite;

      &:nth-child(1) {
        transform: scale(0);
        animation-delay: 1s;
      }

      @keyframes zoomAnim {
        0% {
          transform: scale(0);
          transform-origin: left;
        }

        50% {
          transform: scale(1);
          transform-origin: left;
        }

        50.01% {
          transform: scale(1);
          transform-origin: right;
        }

        100% {
          transform: scale(0);
          transform-origin: right;
        }
      }
    }
  }

  .cmp-loading__style-2 {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: auto;

    &--child {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $color-primary;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }

      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }

      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(24px, 0);
        }
      }
    }
  }

  .cmp-loading__style-3 {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: auto;

    &::after {
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;
      border: 32px solid $color-primary;
      border-color: $color-primary transparent $color-primary transparent;
      animation: lds-hourglass 1.2s infinite;
    }

    @keyframes lds-hourglass {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      100% {
        transform: rotate(1800deg);
      }
    }
  }

  .cmp-loading__style-4 {
    width: 80px;
    height: 80px;
    margin: auto;

    &--child {
      position: absolute;
      width: 6px;
      height: 6px;
      background: $color-primary;
      border-radius: 50%;
      animation: lds-default 1.2s linear infinite;

      @keyframes lds-default {

        0%,
        20%,
        80%,
        100% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.5);
        }
      }

      &:nth-child(1) {
        animation-delay: 0s;
        top: 37px;
        left: 66px;
      }

      &:nth-child(2) {
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
      }

      &:nth-child(3) {
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
      }

      &:nth-child(4) {
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
      }

      &:nth-child(5) {
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
      }

      &:nth-child(6) {
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
      }

      &:nth-child(7) {
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
      }

      &:nth-child(8) {
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
      }

      &:nth-child(9) {
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
      }

      &:nth-child(10) {
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
      }

      &:nth-child(11) {
        animation-delay: -1s;
        top: 62px;
        left: 52px;
      }

      &:nth-child(12) {
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
      }
    }
  }

  .cmp-loading__style-5 {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: auto;

    &::after {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $color-primary;
      border-color: $color-primary transparent $color-primary transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.cmp-loading {
  &--white {
    .cmp-loading__style-1 {
      &--child {
        background-color: $color-white;
      }
    }

    .cmp-loading__style-2 {
      &--child {
        background: $color-white;
      }
    }

    .cmp-loading__style-3 {
      &::after {
        border: 32px solid $color-white;
        border-color: $color-white transparent $color-white transparent;
      }
    }

    .cmp-loading__style-4 {
      &--child {
        background: $color-white;
      }
    }

    .cmp-loading__style-5 {
      &::after {
        border: 6px solid $color-white;
        border-color: $color-white transparent $color-white transparent;
      }
    }
  }
}

.cmp-loading {
  &--secondary {
    .cmp-loading__style-1 {
      &--child {
        background-color: $color-secondary;
      }
    }

    .cmp-loading__style-2 {
      &--child {
        background: $color-secondary;
      }
    }

    .cmp-loading__style-3 {
      &::after {
        border: 32px solid $color-secondary;
        border-color: $color-secondary transparent $color-secondary transparent;
      }
    }

    .cmp-loading__style-4 {
      &--child {
        background: $color-secondary;
      }
    }

    .cmp-loading__style-5 {
      &::after {
        border: 6px solid $color-secondary;
        border-color: $color-secondary transparent $color-secondary transparent;
      }
    }
  }
}