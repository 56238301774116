@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-input {
  height: $input-small;
  padding: $input-padding;
  border: $default-border;
  border-radius: $input-border-radius;
  background-color: $color-white;
  transition: $default-transition;
  font-size: $body-size;

  &[type='password'] {
    padding-right: 36px !important;
  }

  &--icon {
    padding-right: 36px;

    &.left {
      padding-left: 36px;
      padding-right: 16px;
    }
  }

  &:focus {
    // box-shadow: $focus-shadow;
    border: $default-border-primary;
  }

  &--error {
    border-color: $color-danger;

    &:focus {
      border-color: $color-danger;
      // box-shadow: $error-shadow;
    }
  }

  &:disabled {
    color: $color-black-500;
    background-color: #f5f5f5;
    border: none;
  }

  &__icon {
    position: absolute !important;
    top: 50%;
    right: 8px;
    transform: translateY(-56%);
    width: 16px;

    @include hover;

    &.left {
      left: 16px;
    }

    &.error {
      & svg {
        background-color: #fff;
      }
    }
  }

  &__icon[type='button'] {
    width: 24px;
  }
}