@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-select {
  text-align: left;

  .cmp-select {
    &__input {
      input {
        &:disabled {
          visibility: visible;
        }
      }
    }

    &__control {
      min-height: $input-small !important;
      // padding: $input-padding;
      border: $default-border;
      background-color: $color-white;
      transition: $default-transition;
      border-radius: $input-border-radius;
      font-size: $body-size;
      max-height: 132px;
      overflow-y: auto;

      &--is-disabled {
        background-color: $color-input-disabled;
        border: none;
        color: $color-grey-800;

        .cmp-select__single-value {
          color: $color-grey-800;
        }
      }

      // &:hover {
      //   border-color: $color-border;
      // }

      &--is-focused {
        // border-color: $color-border;
        box-shadow: none;
        border: $default-border-primary;
      }

      @include mobileScreen {
        height: auto !important;
        padding: 4px 0;
      }
    }

    &__placeholder {
      color: $color-placeholder;
    }

    &__value-container {
      padding: 0 10px;
    }

    &__menu {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      border: $default-border;

      & ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
      }
    }

    &__option {
      font-size: 14px;
      padding-left: 16px;
      padding-right: 16px;

      &--is-selected {
        color: $color-white;
        background-color: $color-primary-500;
        font-weight: 600;
        border: $default-border;

        &::after {
          content: '';
        }

        .sub-label {
          color: $color-primary-100 !important;
        }
      }
    }

    &__multi-value {
      background-color: $color-grey-200;
      border: 1px solid $color-grey-300;
      border-radius: 25px;
      overflow: hidden;

      &__remove {
        svg {
          background-color: $color-grey-800;
          color: $color-grey-200;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  &--error {
    .cmp-select__control {
      border-color: $color-danger;

      &:hover {
        border-color: $color-danger;
      }

      &--is-focused {
        border-color: $color-danger;
        // box-shadow: $error-shadow;
      }
    }
  }
}
