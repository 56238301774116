@use '../scss/vars' as *;
@use '../scss/mixin' as *;

.cmp-popover {
  > .MuiPopover-paper {
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    min-width: 274px;
    margin-top: 4px;
  }

  &.is-mini {
    > .MuiPopover-paper {
      min-width: 174px;
    }
  }
}

button.cmp-popover__button {
  min-width: 0;
  padding: 10px 16px;
  text-transform: none;
}
