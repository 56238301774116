@use '~src/modules/components/scss/vars' as *;
@use '~src/modules/components/scss/mixin' as *;
@use '~src/modules/components/scss/helpers' as *;
@import '~src/modules/styles';
@import '~bulma';
@import './assets/icon-fonts/style.css';
@import './assets/fonts/styles.css';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  line-height: 150%;
  outline: none;
}

html,
body {
  background: $color-primary-background;
  color: $color-text;

  font: {
    family: $font-primary;
    size: $body-size;
  }
}


html.is-clipped,
html.is-clipped .navbar,
.is-clipped .modal {
  padding-right: 10px;
}


a:focus {
  text-decoration: underline;
}

h1 {
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-bold;
  }

  transition: $default-transition-med;
}

h2,
h3,
h4 {
  white-space: pre-wrap;
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-regular;
  }

  transition: $default-transition-med;
}

h1 {
  font-size: 48px;

  @include mobileScreen {
    font-size: 36px;
  }
}

h2 {
  font-size: 36px;

  @include mobileScreen {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;

  @include mobileScreen {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;

  @include mobileScreen {
    font-size: 18px;
  }
}

h5 {
  font-size: 14px;
  font-weight: 500;
  color: $color-black-500;
}

a,
textarea,
input,
select,
button {
  font-family: $font-primary;
  color: $color-text;
  font-size: 14px;
  line-height: 150%;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-placeholder;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-placeholder;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-placeholder;
  }
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Microsoft Edge */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

label {
  color: $color-header;
  margin-bottom: 4px;
  display: block;

  font: {
    size: 14px;
    weight: $font-weight-medium;
  }
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #9e9e9e70;
  border-radius: 8px;
}

.marquee-left {
  overflow: hidden;

  &__text {
    white-space: nowrap;
    animation: marquee-left 6s linear infinite alternate;

    &--hover {
      white-space: nowrap;

      &:hover {
        animation: marquee-left 6s linear infinite alternate;
      }
    }
  }
}

@keyframes marquee-left {
  from {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }

  to {
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
}