@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.tag {
  border-radius: 16px !important;
  font-weight: 500;
  font-size: 14px !important;
  padding: 0 12px !important;
  border: 1px solid #d9d9d9;

  &.is-info.is-light {
    background-color: #d5e4f5 !important;
    color: #2c70cd !important;
  }

  &.is-danger.is-light {
    background-color: #ffc8c8 !important;
    color: #eb5757 !important;
  }

  &.is-warning.is-light {
    background-color: #fff3c8 !important;
    color: #f5a839 !important;
  }

  &.is-success.is-light {
    background-color: #ebf9eb !important;
    color: #0f7625 !important;
  }
}
