@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.cmp-phoneinput {
  &__input {
    height: $input-small;

    input {
      height: $input-small;
      font-size: $body-size;
      padding-top: 2px;
    }
  }

  &__input--error {

    .PhoneInputCountry,
    input {
      border-color: $color-danger;
    }
  }

  &__input--disabled {

    .PhoneInputCountry,
    .PhoneInputCountry .PhoneInputCountryIcon,
    input {
      color: $color-black-100;
      background-color: #f5f5f5;
      border: none;
    }
  }

  &__input--error.PhoneInput--focus {

    .PhoneInputCountry,
    input {
      border-color: $color-danger;
    }
  }
}