@use '../../scss/vars' as *;
@use '../../scss/mixin' as *;

.icon-success {
  display: inline-flex;
  background-color: $color-success;
  border-radius: 999px;
  justify-content: center;
  align-items: center;

  & svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $color-white;
    stroke-width: 2;
    transform: translateX(2.5px);
    transition: all 0.2s ease;

    width: 60px;

    polyline {
      stroke-dasharray: 22;
      stroke-dashoffset: 66;
      animation: show 0.6s forwards;
      animation-delay: 0.2s;
    }

    @keyframes show {
      from {
        stroke-dashoffset: 66;
      }

      to {
        stroke-dashoffset: 42;
      }
    }
  }
}